.dropdown .material-icons {
    font-size: 30px;
  }
  .navbar-nav {
    padding-right: 20px;
  }
  #navbar1{
      background-color: white !important;
      padding: 10px;
      
  }
  .dropdown > .dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
  }
  .dropdown-menu {
    margin-left: -6.8em;
  }
  .dropdown-menu a {
    color: black !important;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    padding: 5px;
  }
  .dropdown-menu:after,
  .dropdown-menu:before {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .dropdown-menu:after {
    border-color: rgba(136, 23, 23, 0);
    border-bottom-color: #ffffff;
    border-width: 10px;
    left: 85%;
    margin-left: -19px;
  }
  .dropdown-menu a .material-icons {
    margin: 0px;
    padding: 3px 10px 0px 0px;
    color: black;
    font-size: 20px;
  }
  .navbar-nav li a {
    color: rgb(0, 0, 0);
    text-decoration: none;
  }
  .navbar-nav li a:hover {
    color: #8facfd;
  }
  .navbar-nav li {
    margin: 10px;
    color: rgb(0, 0, 0);
    cursor: pointer;
    width: fit-content;
    font-size: 18px;
    font-weight: bolder;
    padding:20px 20px 20px 20px;
  }
  .navbar-nav #join a {
    background-color: #374785;
    padding: 2px 5px;
    color: rgb(255, 255, 255) !important;
    border-radius: 5px;
    text-decoration: none;
    display: block;
  }
  .navbar-brand img {
    position: absolute;
    width: 8%;
    margin-top: -1.6%;
  }
  .loginnavbar{
      border-radius: 15px;
      border: 1px solid  #7fa9ec;
      color:#2b6dd6 !important
  }
  .signupnavbar{
      display: flex;
      border-radius: 15px;
      color:white !important;
      background-color: #2b6dd6
  }
  
  .signupnavbar span{
      padding-left: 10px;
  }
  .main-content-features{
    padding-top: 10%;
    padding-left: 15%;
    padding-right: 15%;
  }
  .main-content-features img{
    width: 100%;
    display:block;
    margin-left:auto;
    margin-right:auto;
   
  }
  .main-content-features h2 {
    
    padding-top:10% ;
  color:#374785;
    letter-spacing: 0.2em;
    font-weight:bolder
  }
  .main-content-features p {
    
    padding-top:10% ;
    font-size: 20px;
    font-weight:bolder
  }
 
  .bgimage {
    width: 100%;
    height: 100dvh;
    background-size: cover;
    position: absolute;
    z-index: -12;
    opacity: 0.1;
    top: 0;
  }
  .index_logo {
    width: 10%;
  }
  body {
    overflow-x: hidden;
  }
  .google_button {
    background-color: transparent;
    border: none;
  }
  .google_button img {
    width: 100%;
  }
  .homebg2-content {
    margin-top: 5%;
    text-align: center;
  }
  .homebg2-content p:nth-child(1) {
    font-size: 50px;
  }
  .homebg2-content p:nth-child(2) {
    font-size: 20px;
  }
  .btn-signuphome {
    border-radius: 15px;
    color: white !important;
    background-color: #375fa1;
    font-size: 20px;
    margin-top: 35px;
    width: 20%;
  }
  .username_input {
    width: 100%;
    padding-top: 20px;
    padding-left: 10%;
    display: flex;
  }
  .username_input p {
    font-size: 36px;
    font-weight: bolder;
  
    color: #375fa1;
  }
  .username_input input {
    width: 30%;
    border-top: none;
    border-left: none;
    border-right: none;
    margin: 0px !important;
    outline: none !important;
    border-bottom: 2px solid #375fa1;
    font-size: 25px;
    font-weight: bolder;
    color: #375fa1;
  }
  .noteusername {
    color: red;
    text-align: center;
    font-size: 20px;
    font-weight: bolder;
    padding-top: 70px;
  }
  .username_input input::placeholder {
    color: black;
    font-size: 20px;
    transition: 0.4s;
  }
  .username_input input:focus::placeholder {
    color: transparent;
    transition: 0.4s;
  }
  .btn-username-submit {
    margin: 10px;
  
    border-radius: 25px;
    color: #375fa1;
    background-color: transparent;
    border: 3px solid #375fa1;
    border-top: none;
    border-bottom: none;
    font-weight: bolder;
    outline: none;
  }
  #usernameerror {
    color: red;
    text-align: right;
    margin-right: 80px;
  }
  #login_loader img {
    width: 40%;
  }
  #login_loader {
    display: none;
  }
  .btn-user {
    background-color: #375fa1;
    color: white;
    font-size: 20px;
    margin: 20px;
  }
  @media (max-width: 550px) {
    .btn-signuphome {
      width: 80%;
    }
    .homebg2-content p:nth-child(1) {
      font-size: 30px;
      padding-top: 50px;
      font-weight: bolder;
    }
    .index_logo {
      width: 30%;
    }
    .bgimage {
      background-size: cover;
      position: absolute;
      z-index: -12;
      opacity: 0.2;
      top: 0;
      height: 50vh;
      width: 200%;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .username_input {
      width: 100%;
      padding-top: 20px;
      padding-left: 0%;
      display: block;
   
    }
    .username_input p {
      padding-top: 20px;
      font-size: 20px;
  
      line-height: 0.01;
    }
    .username_input input {
      width: 80%;
     outline:none !important;
      text-align:center
    }
    .noteusername {
    
      font-size: 15px;
     
    }
  
   
  
  }
  @media (min-width: 500px) and (max-width:1024px) {
  
    .btn-signuphome {
      width: 40%;
    }
  }
  @media (max-width: 550px) {
    .main-content-features{
      padding-top: 20%;
      padding-left: 0%;
      padding-right: 0%;
    }
    .main-content-features h2 {
    
     text-align:center
    }
    .main-content-features p {
      
      padding-top:10% ;
      font-size: 20px;
      font-weight:bolder
    }
    .main-content-features img{
      width: 80%;
    }
    .navbar-nav {
      width: 100%;
      text-align: center;
      height: 70vh;
    }
    .navbar-brand img {
      position: absolute;
      width: 30%;
      margin-top: -6.5%;
    }
    .navbar-nav > li {
      padding-right: 50px;
      margin: auto;
      text-align: center;
      font-size: 20px;
    }
  }
  @media (min-width: 500px) and (max-width:1024px) {
    .main-content-features{
      padding-top: 10%;
      padding-left: 0%;
      padding-right: 0%;
    }
    .btn-signuphome {
      width: 90%;
    }
  }