.form-control{
    width: 83%;
    
   border:none;
   border-bottom: 1px solid;
}
.form-control::placeholder{
    font-weight: bold;
    letter-spacing: 0.2em;
}
.topMenu{
  justify-content: right;
  display: grid;
  padding-right: 10%;
}
.topMenu .btn{
    margin-left: 9%;
   border-top-right-radius: 10px;
   border-bottom-left-radius: 10px;
   background-color: rgb(250, 235, 215);
   color: black;
   font-weight: bolder;
   border: none;
   padding-right: 15px;
}
#root .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
  }
  
  /* Snow Theme */
  #root .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }
  
  #root .ql-editor {
    min-height: 18em;
  }

  
  .ios-toggle > input:active + label::before, .ios-toggle > input:checked + label::before {
    width: 60%;
    left: 20%;
    top: 30%;
    height: 40%;
    opacity: 0;
  }
  
  .ios-toggle {
    position: relative;
    display: inline-block;
    width: 3.4em;
    height: 1em;
    margin: auto;
    border-radius: 2em;
    background: #ccc;
    vertical-align: middle;
    margin: 0 5px;
  }
  .ios-toggle > input {
    appearance: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .ios-toggle > input:checked + label {
    background: red;
  }
  .ios-toggle > input:checked + label::after {
    right: 0;
    left: 1.4em;
  }
  .ios-toggle > input:active + label::after {
    right: 1em;
    left: 0;
  }
  .ios-toggle > input:active:checked + label::after {
    right: 0;
    left: 1em;
  }
  .ios-toggle > label {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 2em;
    transition: background 0.125s;
  }
  .ios-toggle > label::before, .ios-toggle > label::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 1em;
    border-radius: inherit;
    transition: all 0.2s;
  }
  .ios-toggle > label::before {
    background: #bada55;
    width: 100%;
  }
  .ios-toggle > label::after {
    background: #fff;
    transform: scale(1.1);
    right: 1.4em;
    box-shadow: 0 0.25em rgba(0, 0, 0, 0.05), inset 0 -2em 2em -2em rgba(0, 0, 0, 0.1);
  }
  

 .editorCon{
  padding-left: 8%;
 }
 .tootleP{
  font-weight: bold;
  font-size: 10px;
 }
 .public-private-toggle{
  font-weight: bolder;
 }