.myblogsection {
   margin-top: 20px;
}
.myblog-box {
    width: 100%;
    min-height: 10dvh;
    border: 1px solid;
    border-radius: 5px;
    margin-bottom: 30px;

    padding: 10px;
}
.myblog-box .writeIcon {
    font-size: 50px;

}
.myblog-box .deleteIcon {
    float: right;
    width: fit-content;
    cursor: pointer;
}
.myblog-box p {
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}
.myblog-box label {
    font-size: 10px;
}
#root .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
}

/* Snow Theme */
#root .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

#root .ql-editor {
    min-height: 18em;
}
.noBlog img {
   width: 50%;
}
.noBlog{
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.noBlog  p{
    font-size: 20px;
    font-weight: bold;
}
#myblogSection{
    display: none;
}
.ios-toggle1 > input:active + label::before, .ios-toggle1 > input:checked + label::before {
    width: 60%;
    left: 20%;
    top: 30%;
    height: 40%;
    opacity: 0;
  }
.ios-toggle1 {
    position: relative;
    display: inline-block;
    width: 3em;
    height: 1em;
    margin: auto;
    border-radius: 2em;
    background: #ccc;
    vertical-align: middle;
    margin: 0 5px;
  }
  .ios-toggle1 > input {
    appearance: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .ios-toggle1 > input:checked + label {
    background: red;
  }
  .ios-toggle1 > input:checked + label::after {
    right: 0;
    left: 1.4em;
  }
  .ios-toggle1 > input:active + label::after {
    right: 1em;
    left: 0;
  }
  .ios-toggle1 > input:active:checked + label::after {
    right: 0;
    left: 1em;
  }
  .ios-toggle1 > label {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 2em;
    transition: background 0.125s;
  }
  .ios-toggle1 > label::before, .ios-toggle1 > label::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 1em;
    border-radius: inherit;
    transition: all 0.2s;
  }
  .ios-toggle1 > label::before {
    background: #bada55;
    width: 100%;
  }
  .ios-toggle1 > label::after {
    background: #fff;
    transform: scale(1.1);
    right: 1.4em;
    box-shadow: 0 0.25em rgba(0, 0, 0, 0.05), inset 0 -2em 2em -2em rgba(0, 0, 0, 0.1);
  }
  .visivility {
    font-size: 10px;
    position: absolute;
    font-weight: bold;
  }