.skeleton{
    width: 100%;
    height: 20vh;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  margin-top: 30px;
}
.skeleton {
    animation: skeleton-loading1 1s linear infinite alternate;
  }
  
  @keyframes skeleton-loading1 {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  .contentloding{
    margin-top: 5%;
   
  }
 .contentloding h1{
  width: 40%;
  text-align: center;
  padding: 10px;
  animation: skeleton-loading1 1s linear infinite alternate;
 } 
 .contentloding  p{
  width: 90%;
  margin-top: 30px;
  padding: 10px;
  margin-left: 50px;
  animation: skeleton-loading1 1s linear infinite alternate;
 }

 @media (max-width: 800px) {
  .contentloding{
    margin-top: 20%;
   
  }
  .contentloding h1{
    width: 30%;
   
   } 
   .contentloding  p{
    width: 72%;
   
   }
 
}