.dashboard {
    height: 100dvh;

}
.first-section {
    width: 10%;
    background-color: #faebd7;
    height: 100dvh;
    float: left;
}
.second-section {
    width: 90%;
    height: 100dvh;
    float: right;
    overflow-y: auto;
 
}
.first-section .menu-section {
    margin-top: 80%;
}
a{
    text-decoration: none !important;
}
.first-section .menu-section a li {
    list-style: none;
    margin-bottom: 20px;
    font-weight: bolder;
    display: flex;
    padding: 5px;
    margin-left: 20px;
    width: fit-content;
    cursor: pointer;
    font-size: 1rem;
    color: black;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
}
.first-section .menu-section li span {
    margin-right: 10px;
}
.icon-section img {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}
.Pageheading {
    font-size: 20px;
    margin: 10px;
    letter-spacing: 0.2em;
    font-weight: bolder;
    visibility: hidden;
}
.icon-section p{
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    margin-top:30px
}
.ownername{
    position: absolute;
    bottom: 0;
    font-weight: bolder;
    font-size: 10px;
    margin-left: 20px;
    margin-bottom: 20px;
}
#closeMenu{
    position: absolute;
   right: 0;
    margin-right: 20px;
    font-weight: bolder;
    font-size: 35px;
    display: none;
    z-index:3
}
#openMenu{
    position: absolute;
     margin: 20px;
     font-weight: bolder;
     font-size: 35px;
     cursor: pointer;
     width: fit-content;
  z-index:1;  display: none;
}

@media (max-width: 1200px) {
    }
@media (max-width: 1000px) {
   
}
@media (max-width: 800px) {
    #closeMenu,#openMenu{

        display: block;
    }
    #firstSection {
        width: 80%;
       position: absolute;
       z-index: 2;
       left: -100%;
       transition: 1s;
        float: none;
    }
    .first-section .menu-section {
        margin-top: 10%;
        margin-left:25%;
    }
    .second-section {
        width: 100%;
        float: none;
    }
}
@media (max-width: 400px) {
   
}
