.closeModal {
    float: right;
    cursor: pointer;
}
.modal-body {
    text-align: center;
}
.modal-body .folder {
    font-size: 150px;
    color: rgb(222, 161, 77);
}
.modal-body input {
    border: 1px solid black;
    border-top: none;
    border-left: none;
    border-right: none;
}
.third-party-login-icon {
    padding-top: 10%;
}
.third-party-login-icon span img {
    width: 70%;
    padding-top: 20px;
    cursor: pointer;
}
.switch-button .btn {
    width: 50%;
    margin-bottom: 50px;
    outline: none !important;
}

.modal-body .index_logo{
    width: 40% !important;
   
}