.searchSection .form-group{
    display: inline-flex;
    width: 70%;
}
.searchSection{
    width: 70%;
    margin-top: 5%;
}
.searchSection input{
    padding: 5px;
   width: 90%;
  border:1px solid
}
.searchSection input::placeholder{
    font-weight: bold;
}
.searchSection .btn{
    width: 20%;

}
#sechocontent-main{
    display: none;
}
@media (max-width: 800px) {
    .searchSection .form-group{

        width: 100%;
        margin-top: 20%;
    }
    .searchSection{
        width: 100%;
        
    }
}