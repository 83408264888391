.content {
    margin-top: 20px;
    padding: 20px 20px 10% 0px;
}
.content #contentTitle {
    text-align: center;
    font-weight: bolder;
    font-size: 30px;
    padding: 10px;
}
.content #Aname {
    text-align: center;
    font-size: 13px;
}
#contentBody {
    display: none;
}
.content #Aname label {

    padding-left: 30px;
}
.content #Aname span {
    font-size: 2px;
    padding: 10px;
}

.contString > h1 {
    font-size: 28px;
}
.contString > h2 {
    font-size: 25px;
}
.contString > h3 {
    font-size: 22px;
}
.contString > h4 {
    font-size: 19px;
}
.contentThought{
    padding: 80px 15% 80px 15%;
}
.backIcon {
    font-size: 40px;
    cursor: pointer;
    width: fit-content;
    font-weight: bold;
    text-decoration: none;
    color: black;
}
#root .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
}
.ql-align-center {
    justify-content: center;
    text-align: center;
}
/* Snow Theme */
#root .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}
.content img {
    width: 50dvw;
}
#root .ql-editor {
    min-height: 18em;
}
@media (max-width: 800px) {
    .content {
        margin-top: 50px;
        padding: 00px 0px 0 0px;
    }
    .content #contentTitle {
        text-align: center;
        letter-spacing: 0;
        font-weight: bolder;
        font-size: 20px;
    }
    .contentThought{
        padding: 20px 5% 80px 5%;
    }
}
.privateIcon {
    text-align: center;
    margin-top: 10%;
    font-size: 30px;
    font-weight: bolder;
}
.privateIcon .material-icons {
    font-size: 50px;
}
